import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import ProjectManagement from 'pages/dashboard/ProjectManagement';
import CreateNew from 'pages/apps/project-management/CreateNew';
import ProjectListView from 'pages/apps/project-management/ProjectListView';
import ProjectDetails from 'pages/apps/project-management/ProjectDetails';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import ProjectTodoList from 'pages/apps/project-management/ProjectTodoList';
import ProjectBoardView from 'pages/apps/project-management/ProjectBoardView';
import ProjectCardView from 'pages/apps/project-management/ProjectCardView';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import Showcase from 'pages/Showcase';
import App from 'App';
import CardSignIn from 'pages/pages/authentication/card/SignIn';
import CardSignUp from 'pages/pages/authentication/card/SignUp';
import CardForgotPassword from 'pages/pages/authentication/card/ForgotPassword';
import CardSignOut from 'pages/pages/authentication/card/SignOut';
import CardResetPassword from 'pages/pages/authentication/card/ResetPassword';
import CardTwoFA from 'pages/pages/authentication/card/TwoFA';
import CardLockScreen from 'pages/pages/authentication/card/LockScreen';
import IndustryListView from 'pages/apps/industry/IndustryListView';
import SupplierListView from 'pages/apps/supplier/SupplierListView';
import CreateSupplier from 'pages/apps/supplier/CreateSupplier';
import UnitListView from 'pages/unit/UnitListView';
import StageListView from 'pages/apps/stage/StageListView';
import ContractorsListView from 'pages/apps/contractor/ContractorsListView';
import CreateContractors from 'pages/apps/contractor/CreateContractors';
import WorkOrderListView from 'pages/apps/workOrder/WorkOrderListView';
import CreateWorkOrder from 'pages/apps/workOrder/CreateWorkOrder';
import SiteListCardView from 'pages/apps/site/SiteListCardView';
import MaterialListView from 'pages/apps/material/MaterialListView';
import IndustryMaterialView from 'pages/apps/material/IndustryMaterialView';
import CreateMaterial from 'pages/apps/material/CreateMaterial';
import CreateMaterialFormat from 'pages/apps/material/CreateMaterialFormat';
import CreateNewSite from 'pages/apps/site/CreateNewSite';
import SiteDetails from 'pages/apps/site/SiteDetails';
import { setAuthToken } from 'apiService';
import ExpenseListView from 'pages/apps/expenseMaster/ExpenseListView';
import CompanyListView from 'pages/apps/company/CompanyListView';
import CompanyRateList from 'pages/apps/company/CompanyRateList';
import CreateCompanyRateList from 'pages/apps/company/CreateCompanyRateList';
import InventoryViewList from 'pages/apps/inventory/InventoryViewList';
import ShortInventoryList from 'pages/apps/inventory/ShortInventoryList';
import OutOfStockList from 'pages/apps/inventory/OutOfStockList';
import CreateInventory from 'pages/apps/inventory/CreateInventory';
import AccountsViewList from 'pages/apps/inventory/AccountsViewList';
import ReturnGoodsViewList from 'pages/apps/inventory/ReturnGoodsViewList';
import AccountList from 'pages/apps/inventory/AccountList';
import DispatchChallanTable from 'pages/apps/inventory/DispatchChallanTable';
import ChallanReturnTable from 'pages/apps/inventory/ChallanReturnTable';
import PurchaseReturnTable from 'pages/apps/inventory/PurchaseReturnTable';
import GstReportTable from 'pages/apps/inventory/GstReportTable';
import VoucherExpensesTable from 'pages/apps/inventory/VoucherExpensesTable';
import SiteRetentionListView from 'pages/apps/site/SiteRetentionListView';
import CreateSiteRetention from 'pages/apps/site/CreateSiteRetention';
import SitePendingListTable from 'pages/apps/site/SitePendingListTable';
import SiteApprovalListTable from 'pages/apps/site/SiteApprovalListTable';
import SitesReportViewTable from 'pages/apps/site/SitesReportViewTable';
import Challan from 'pages/apps/inventory/Challan';
import SupplierInvoiceTable from 'pages/apps/supplier/SupplierInvoiceTable';
import SupplierInvoicePayment from 'pages/apps/inventory/SupplierInvoicePayment';
import SupplierPaymentDetails from 'pages/apps/supplier/SupplierPaymentDetails';
import SupplierInvoiceDetails from 'pages/apps/supplier/SupplierInvoiceDetails';
import CompletedSiteCardView from 'pages/apps/site/CompletedSiteCardView';
import PaymentReceipt from 'pages/apps/supplier/PaymentReceipt';
import CompletedSiteDetails from 'pages/apps/site/CompletedSiteDetails';
import EditSiteDetails from 'pages/apps/site/EditSiteDetails';
import CompanyBoq from 'pages/apps/site/CompanyBoq';
import UserListView from 'pages/apps/user/UserListView';
import WorkOrderDetails from 'pages/apps/workOrder/WorkOrderDetails';
import WorkOrderInvoice from 'pages/apps/workOrder/WorkOrderInvoice';
import ContractorProfile from 'pages/apps/contractor/ContractorProfile';
import ProfarmaInvoice from 'pages/apps/workOrder/ProfarmaInvoice';
import WorkOrderDocuments from 'pages/apps/workOrder/WorkOrderDocuments';
import StageList from 'pages/apps/stage/StageList';
import CompanyMaterial from 'pages/apps/company/CompanyMaterial';
import StateViewList from 'pages/State/StateViewList';
import ItemCodeView from 'pages/apps/itemList/ItemCodeView';
import DamageList from 'pages/apps/inventory/DamageList';
import QuotationListView from 'pages/apps/quotation/QuotationListView';
import Quotation from 'pages/apps/quotation/Quotation';
import CreateQuotation from 'pages/apps/quotation/CreateQuotation';
import PaymentListView from 'pages/apps/invoice/PaymentListView';
import PurchasingListView from 'pages/apps/invoice/PurchasingListView';
import AddPurchasing from 'pages/apps/invoice/AddPurchasing';
import AddPayment from 'pages/apps/invoice/AddPayment';
import InvestorListView from 'pages/apps/Investor/InvestorListView';
import CreateInvestor from 'pages/apps/Investor/CreateInvestor';
import InvestorInvoiceTable from 'pages/apps/Investor/InvestorInvoiceTable';
import NewChallan from 'pages/apps/quotation/NewChallan';
import InventoryViewTable from 'pages/apps/site/InventoryViewTable';
import ContractorStockListView from 'pages/apps/contractor/ContractorStockListView';
import MasterInventoryStock from 'pages/apps/inventory/MasterInventoryStock';
import InActiveSiteListCardView from 'pages/apps/site/InActiveSiteListCardView';

export const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <ProjectManagement /> // <Ecommerce />
          },
          {
            path: 'industry',
            element: <IndustryListView />
          },
          { path: 'suppliers', element: <SupplierListView /> },
          { path: 'workOrder', element: <WorkOrderListView /> },
          { path: 'profarmaInvoice', element: <ProfarmaInvoice /> },
          { path: 'workOrderDetails/:id', element: <WorkOrderDetails /> },
          { path: 'workOrderDocuments/:id', element: <WorkOrderDocuments /> },
          { path: 'workOrderInvoice', element: <WorkOrderInvoice /> },
          { path: 'material', element: <MaterialListView /> },
          { path: 'industrymaterial', element: <IndustryMaterialView /> },
          { path: 'contractorProfile/:id', element: <ContractorProfile /> },
          { path: 'expenseList', element: <ExpenseListView /> },
          { path: 'itemCode', element: <ItemCodeView /> },
          { path: 'companyList', element: <CompanyListView /> },
          { path: 'companyRateList', element: <CompanyRateList /> },
          { path: 'companyMaterial', element: <CompanyMaterial /> },
          { path: 'createCompanyRateList', element: <CreateCompanyRateList /> },
          {
            path: 'createCompanyRateList/:id',
            element: <CreateCompanyRateList />
          },
          { path: 'inventoryViewList', element: <InventoryViewList /> },
          { path: 'shortInventoryList', element: <ShortInventoryList /> },
          { path: 'outOfStockList', element: <OutOfStockList /> },
          { path: 'createInventory', element: <CreateInventory /> },
          { path: 'accountsViewList', element: <AccountsViewList /> },
          { path: 'dispatchChallanTable', element: <DispatchChallanTable /> },
          { path: 'returnGoodsViewList', element: <ReturnGoodsViewList /> },
          { path: 'accountList', element: <AccountList /> },
          { path: 'damageList', element: <DamageList /> },
          { path: 'challanReturnTable', element: <ChallanReturnTable /> },
          { path: 'purchaseReturnTable', element: <PurchaseReturnTable /> },
          { path: 'paymentReceipt/:id', element: <PaymentReceipt /> },
          { path: 'quotationListView', element: <QuotationListView /> },
          { path: 'quotation', element: <Quotation /> },
          { path: 'quotation/create-new', element: <CreateQuotation /> },
          { path: 'quotation/create-new/:id', element: <CreateQuotation /> },
          { path: 'newChallan/:id', element: <NewChallan /> },
          { path: 'paymentListView', element: <PaymentListView /> },
          { path: 'paymentListView/:id', element: <PaymentListView /> },
          { path: 'purchasingListView', element: <PurchasingListView /> },
          { path: 'addPurchasing', element: <AddPurchasing /> },
          { path: 'addPurchasing/:id', element: <AddPurchasing /> },
          { path: 'addPayment', element: <AddPayment /> },
          { path: 'addPayment/:id', element: <AddPayment /> },
          {
            path: 'supplierInvoicePayment',
            element: <SupplierInvoicePayment />
          },
          {
            path: 'supplireInvoiceTable/:id',
            element: <SupplierInvoiceTable />
          },
          { path: 'gstReportTable', element: <GstReportTable /> },
          { path: 'voucherExpensesTable', element: <VoucherExpensesTable /> },
          { path: 'siteRetentionListView', element: <SiteRetentionListView /> },
          { path: 'createSiteRetention', element: <CreateSiteRetention /> },
          { path: 'sitePendingListTable', element: <SitePendingListTable /> },
          { path: 'siteApprovalListTable', element: <SiteApprovalListTable /> },
          { path: 'sitesReportViewTable', element: <SitesReportViewTable /> },
          { path: 'challan/:id', element: <Challan /> },
          { path: 'companyBoq', element: <CompanyBoq /> },
          { path: 'userListView', element: <UserListView /> },
          { path: 'investor', element: <CreateInvestor /> },
          { path: 'investor/:id', element: <CreateInvestor /> },
          { path: 'investorListView', element: <InvestorListView /> },
          { path: 'masterInventoryStock', element: <MasterInventoryStock /> },
          {
            path: 'contractorStockList/:id',
            element: <ContractorStockListView />
          },
          {
            path: 'investorInvoiceTable/:id',
            element: <InvestorInvoiceTable />
          },
          {
            path: 'supplierPaymentDetails/:id',
            element: <SupplierPaymentDetails />
          },
          {
            path: 'supplierInvoiceDetails/:id',
            element: <SupplierInvoiceDetails />
          },
          {
            path: 'workOrder/create-new',
            element: <CreateWorkOrder />
          },
          {
            path: 'workOrder/create-new/:id',
            element: <CreateWorkOrder />
          },
          {
            path: 'suppliers/create-new',
            element: <CreateSupplier />
          },
          {
            path: 'suppliers/create-new/:id',
            element: <CreateSupplier />
          },
          {
            path: 'material/create-new',
            element: <CreateMaterial />
          },
          {
            path: 'material/create-new/:id',
            element: <CreateMaterial />
          },
          {
            path: 'site/create-new/:id',
            element: <CreateNewSite />
          },
          {
            path: 'site/editSite/:id',
            element: <EditSiteDetails />
          },
          {
            path: 'site/create-new',
            element: <CreateNewSite />
          },
          {
            path: 'SiteDetails/:id',
            element: <SiteDetails />
          },
          {
            path: 'completedSiteDetails/:id',
            element: <CompletedSiteDetails />
          },
          {
            path: 'MaterialFormat/create-new',
            element: <CreateMaterialFormat />
          },
          {
            path: 'unit',
            element: <UnitListView />
          },
          {
            path: 'stage',
            element: <StageListView />
          },
          {
            path: 'stageList',
            element: <StageList />
          },
          {
            path: 'contractors',
            element: <ContractorsListView />
          },
          {
            path: 'contractors/create-new',
            element: <CreateContractors />
          },
          {
            path: 'contractors/create-new/:id',
            element: <CreateContractors />
          },
          {
            path: 'siteList',
            element: <SiteListCardView />
          },
          {
            path: 'completedSiteList',
            element: <CompletedSiteCardView />
          },
          {
            path: 'inActiveSiteList',
            element: <InActiveSiteListCardView />
          },
          {
            path: 'stateViewList',
            element: <StateViewList />
          },

          {
            path: 'project-management',
            children: [
              {
                path: 'create-new',
                element: <CreateNew />
              },
              {
                path: 'project-list-view',
                element: <ProjectListView />
              },
              {
                path: 'project-card-view',
                element: <ProjectCardView />
              },
              {
                path: 'project-board-view',
                element: <ProjectBoardView />
              },
              {
                path: 'todo-list',
                element: <ProjectTodoList />
              },
              {
                path: 'project-details',
                element: <ProjectDetails />
              }
            ]
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/login',
        element: <CardSignIn />
      },
      {
        path: '/sign-out',
        element: <CardSignOut />
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];
export const routesForInvManager: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <ProjectManagement /> // <Ecommerce />
          },
          {
            path: 'industry',
            element: <IndustryListView />
          },
          { path: 'suppliers', element: <SupplierListView /> },
          { path: 'profarmaInvoice', element: <ProfarmaInvoice /> },
          { path: 'material', element: <MaterialListView /> },
          { path: 'industrymaterial', element: <IndustryMaterialView /> },
          { path: 'inventoryViewList', element: <InventoryViewList /> },
          { path: 'shortInventoryList', element: <ShortInventoryList /> },
          { path: 'outOfStockList', element: <OutOfStockList /> },
          { path: 'createInventory', element: <CreateInventory /> },
          { path: 'accountsViewList', element: <AccountsViewList /> },
          { path: 'dispatchChallanTable', element: <DispatchChallanTable /> },
          { path: 'returnGoodsViewList', element: <ReturnGoodsViewList /> },
          { path: 'accountList', element: <AccountList /> },
          { path: 'damageList', element: <DamageList /> },
          { path: 'challanReturnTable', element: <ChallanReturnTable /> },
          { path: 'purchaseReturnTable', element: <PurchaseReturnTable /> },
          { path: 'paymentReceipt/:id', element: <PaymentReceipt /> },
          {
            path: 'supplierInvoicePayment',
            element: <SupplierInvoicePayment />
          },
          {
            path: 'supplireInvoiceTable/:id',
            element: <SupplierInvoiceTable />
          },
          { path: 'gstReportTable', element: <GstReportTable /> },
          { path: 'voucherExpensesTable', element: <VoucherExpensesTable /> },
          { path: 'sitesReportViewTable', element: <SitesReportViewTable /> },
          { path: 'challan/:id', element: <Challan /> },
          {
            path: 'suppliers/create-new',
            element: <CreateSupplier />
          },
          {
            path: 'suppliers/create-new/:id',
            element: <CreateSupplier />
          },
          {
            path: 'material/create-new',
            element: <CreateMaterial />
          },
          {
            path: 'material/create-new/:id',
            element: <CreateMaterial />
          },
          {
            path: 'site/create-new/:id',
            element: <CreateNewSite />
          },
          {
            path: 'MaterialFormat/create-new',
            element: <CreateMaterialFormat />
          },
          {
            path: 'unit',
            element: <UnitListView />
          },
          {
            path: 'site/create-new/:id',
            element: <CreateNewSite />
          },
          {
            path: 'site/editSite/:id',
            element: <EditSiteDetails />
          },
          {
            path: 'site/create-new',
            element: <CreateNewSite />
          },
          {
            path: 'SiteDetails/:id',
            element: <SiteDetails />
          },
          {
            path: 'completedSiteDetails/:id',
            element: <CompletedSiteDetails />
          },
          {
            path: 'siteList',
            element: <SiteListCardView />
          },
          {
            path: 'completedSiteList',
            element: <CompletedSiteCardView />
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/login',
        element: <CardSignIn />
      },
      {
        path: '/sign-out',
        element: <CardSignOut />
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const routeForStaff: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <ProjectManagement /> // <Ecommerce />
          },
          {
            path: 'industry',
            element: <IndustryListView />
          },
          { path: 'suppliers', element: <SupplierListView /> },
          { path: 'workOrderInvoice', element: <WorkOrderInvoice /> },
          { path: 'workOrderDetails', element: <WorkOrderDetails /> },
          { path: 'workOrderDocuments/:id', element: <WorkOrderDocuments /> },
          { path: 'workOrder', element: <WorkOrderListView /> },
          { path: 'material', element: <MaterialListView /> },
          { path: 'industrymaterial', element: <IndustryMaterialView /> },
          { path: 'expenseList', element: <ExpenseListView /> },
          { path: 'contractorProfile', element: <ContractorProfile /> },
          { path: 'companyList', element: <CompanyListView /> },
          { path: 'companyRateList', element: <CompanyRateList /> },
          { path: 'contractorProfile', element: <ContractorProfile /> },
          { path: 'createCompanyRateList', element: <CreateCompanyRateList /> },
          {
            path: 'createCompanyRateList/:id',
            element: <CreateCompanyRateList />
          },
          { path: 'inventoryViewList', element: <InventoryViewList /> },
          { path: 'shortInventoryList', element: <ShortInventoryList /> },
          { path: 'outOfStockList', element: <OutOfStockList /> },
          { path: 'createInventory', element: <CreateInventory /> },
          { path: 'accountsViewList', element: <AccountsViewList /> },
          { path: 'dispatchChallanTable', element: <DispatchChallanTable /> },
          { path: 'returnGoodsViewList', element: <ReturnGoodsViewList /> },
          { path: 'accountList', element: <AccountList /> },
          { path: 'damageList', element: <DamageList /> },
          { path: 'challanReturnTable', element: <ChallanReturnTable /> },
          { path: 'purchaseReturnTable', element: <PurchaseReturnTable /> },
          { path: 'paymentReceipt/:id', element: <PaymentReceipt /> },
          {
            path: 'supplierInvoicePayment',
            element: <SupplierInvoicePayment />
          },
          {
            path: 'supplireInvoiceTable/:id',
            element: <SupplierInvoiceTable />
          },
          { path: 'gstReportTable', element: <GstReportTable /> },
          { path: 'voucherExpensesTable', element: <VoucherExpensesTable /> },
          { path: 'siteRetentionListView', element: <SiteRetentionListView /> },
          { path: 'createSiteRetention', element: <CreateSiteRetention /> },
          { path: 'sitePendingListTable', element: <SitePendingListTable /> },
          { path: 'siteApprovalListTable', element: <SiteApprovalListTable /> },
          { path: 'sitesReportViewTable', element: <SitesReportViewTable /> },
          { path: 'challan/:id', element: <Challan /> },
          { path: 'companyBoq', element: <CompanyBoq /> },
          {
            path: 'supplierPaymentDetails/:id',
            element: <SupplierPaymentDetails />
          },
          {
            path: 'supplierInvoiceDetails/:id',
            element: <SupplierInvoiceDetails />
          },
          {
            path: 'workOrder/create-new',
            element: <CreateWorkOrder />
          },
          {
            path: 'workOrder/create-new/:id',
            element: <CreateWorkOrder />
          },
          {
            path: 'suppliers/create-new',
            element: <CreateSupplier />
          },
          {
            path: 'suppliers/create-new/:id',
            element: <CreateSupplier />
          },
          {
            path: 'material/create-new',
            element: <CreateMaterial />
          },
          {
            path: 'material/create-new/:id',
            element: <CreateMaterial />
          },
          {
            path: 'site/create-new/:id',
            element: <CreateNewSite />
          },
          {
            path: 'site/editSite/:id',
            element: <EditSiteDetails />
          },
          {
            path: 'site/create-new',
            element: <CreateNewSite />
          },
          {
            path: 'SiteDetails/:id',
            element: <SiteDetails />
          },
          {
            path: 'completedSiteDetails/:id',
            element: <CompletedSiteDetails />
          },
          {
            path: 'MaterialFormat/create-new',
            element: <CreateMaterialFormat />
          },
          {
            path: 'unit',
            element: <UnitListView />
          },
          {
            path: 'stage',
            element: <StageListView />
          },
          {
            path: 'stageList',
            element: <StageList />
          },
          {
            path: 'contractors',
            element: <ContractorsListView />
          },
          {
            path: 'contractors/create-new',
            element: <CreateContractors />
          },
          {
            path: 'contractors/create-new/:id',
            element: <CreateContractors />
          },
          {
            path: 'siteList',
            element: <SiteListCardView />
          },
          {
            path: 'completedSiteList',
            element: <CompletedSiteCardView />
          },

          {
            path: 'project-management',
            children: [
              {
                path: 'create-new',
                element: <CreateNew />
              },
              {
                path: 'project-list-view',
                element: <ProjectListView />
              },
              {
                path: 'project-card-view',
                element: <ProjectCardView />
              },
              {
                path: 'project-board-view',
                element: <ProjectBoardView />
              },
              {
                path: 'todo-list',
                element: <ProjectTodoList />
              },
              {
                path: 'project-details',
                element: <ProjectDetails />
              }
            ]
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/login',
        element: <CardSignIn />
      },
      {
        path: '/sign-out',
        element: <CardSignOut />
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const routeForContractor: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <SiteListCardView />
          },
          { path: 'SiteDetails/:id', element: <SiteDetails /> },
          {
            path: 'completed',
            element: <CompletedSiteCardView />
          },
          {
            path: 'completedSiteDetails/:id',
            element: <CompletedSiteDetails />
          },
          { path: 'report', element: <SitesReportViewTable /> }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/login',
        element: <CardSignIn />
      },
      {
        path: '/sign-out',
        element: <CardSignOut />
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const withoutLogin: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        index: true,
        path: '/login',
        element: <CardSignIn />
      },
      {
        path: '/sign-up',
        element: <CardSignUp />
      },
      {
        path: '/sign-out',
        element: <CardSignOut />
      },
      {
        path: '/forgot-password',
        element: <CardForgotPassword />
      },
      {
        path: '/reset-password',
        element: <CardResetPassword />
      },
      {
        path: '/lock-screen',
        element: <CardLockScreen />
      },
      {
        path: '/2FA',
        element: <CardTwoFA />
      },
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

let router = createBrowserRouter(withoutLogin);
if (localStorage.getItem('token')) {
  const role = Number(localStorage.getItem('role'));
  if (role === 1) {
    router = createBrowserRouter(routes);
  }
  if (role === 2) {
    router = createBrowserRouter(routesForInvManager);
  }
  if (role === 3) {
    router = createBrowserRouter(routeForStaff);
  }
  if (role === 4) {
    router = createBrowserRouter(routeForContractor);
  }
  setAuthToken(localStorage.getItem('token'));
}

export default router;
