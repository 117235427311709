import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import {
  faFileDownload,
  faFileExport,
  faFilePdf,
  faPaperclip,
  faPlus,
  faRemove
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDate,
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';
import { toast } from 'react-toastify';
import Avatar from 'components/base/Avatar';
import { baseLocalURL } from 'config';

interface Project {
  id: number;
  doc_title: string;
  created_at: string;
  document: any;
}

interface Field {
  site_id: number;
  name: string;
  document: any;
}
interface Props {
  siteId: any;
}

// eslint-disable-next-line react/prop-types
const SiteDocumentListTable: React.FC<Props> = ({ siteId }) => {
  const [show, setShow] = useState(false);
  const [expensesList, setExpensesList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [expenseId, setExpenseId] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setFields(prevFields => ({
        ...prevFields,
        document: file
      }));
    }
  };

  const [fields, setFields] = useState<Field>({
    site_id: siteId,
    document: '',
    name: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleShow = (data: any) => {
    setShow(true);
    setExpenseId(data.id);
    setFields({
      site_id: siteId,
      name: data.doc_title,
      document: data.document
    });
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setExpenseId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`site_doc/${expenseId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setExpenseId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleClose = () => {
    setShow(false);
    setExpenseId('');
    setImage(null);
    // setIndustryName('');
    // setError('');
    setFields({
      site_id: siteId,
      name: '',
      document: ''
    });
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    const data = jsonToFormData(fields);
    let response: any = {};
    if (expenseId) {
      response = await addService(`site_doc/${expenseId}`, data);
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        fetch();
        handleClose();
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    } else {
      response = await addService(`site_doc`, data);
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        fetch();
        handleClose();
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`site_doc_by_site_id/${siteId}`);
    if (response.status === 200) {
      setExpensesList(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'doc_title',
      header: 'Document Name',
      cell: ({ row: { original } }) => {
        // const { doc_title, profile_image } = original;
        const { doc_title, document } = original;
        return (
          <a
            href={`${baseLocalURL}/public/${document}`}
            // download={document}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center text-body"
          >
            {document.endsWith('.pdf') ? (
              <FontAwesomeIcon icon={faFilePdf} className="fs-5" />
            ) : (
              <Avatar src={`${baseLocalURL}/public/${document}`} size="m" />
            )}
            <p style={{ color: '#85A9FF' }} className="mb-0 ms-3 fw-semibold">
              {doc_title}
            </p>
          </a>
        );
      },
      meta: {
        headerProps: { style: { width: '55%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      // accessorKey: 'expenses_date',
      header: 'Download',
      cell: ({ row: { original } }) => {
        const { document } = original;
        return (
          <>
            <a
              href={`${baseLocalURL}/public/${document}`}
              target="_blank"
              rel="noopener noreferrer"
              download={document}
            >
              <FontAwesomeIcon icon={faFileDownload} className="fs-5" />
            </a>
          </>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    // {
    //   accessorKey: 'amount',
    //   header: 'File Format',
    //   meta: {
    //     cellProps: { className: 'ps-3 text-body py-4' },
    //     headerProps: { style: { width: '12%' }, className: 'ps-3' }
    //   }
    // },
    {
      accessorKey: 'created_at',
      header: 'Upload Date',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return <>{formatDate(created_at)}</>;
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1 || role === 3) {
          return (
            <>
              <RevealDropdownTrigger>
                <RevealDropdown>
                  <ActionDropdownItems
                    editAction={() => handleShow(original)}
                    removeAction={() => confirmAlert(id)}
                  />
                </RevealDropdown>
              </RevealDropdownTrigger>
            </>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: expensesList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Document'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h4 className="mb-5">Document List</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>

              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {(role === 1 || role === 3 || role === 4) && (
                  <Button
                    onClick={handleShow}
                    variant="primary"
                    className="px-5"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Upload Document
                  </Button>
                )}
                {/* <Button onClick={handleShow} variant="primary" className="px-5">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Print All
                </Button> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              {' '}
              <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Document Name"
                      >
                        <Form.Control
                          placeholder="name"
                          name="name"
                          value={fields['name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>

                    <Col sm={4} md={6}>
                      <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                        <div className="p-2 d-flex align-items-center">
                          <label
                            className="text-body fs-9 cursor-pointer"
                            htmlFor="attachments"
                          >
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className="me-1"
                            />
                            <span className="ml-2">Upload Document</span>
                          </label>
                        </div>
                        <Form.Control
                          className="d-none"
                          type="file"
                          id="attachments"
                          onChange={handleImageChange}
                        />
                      </div>
                      {image && (
                        <div className="mt-3 border rounded position-relative">
                          <img
                            src={URL.createObjectURL(image)}
                            alt="Uploaded"
                            style={{ maxWidth: '100%' }}
                          />
                          <FontAwesomeIcon
                            onClick={() => setImage(null)}
                            icon={faRemove}
                            className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SiteDocumentListTable;
