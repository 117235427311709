import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

interface Project {
  id: number;
  expensemaster: {
    expense_title: string;
  };
  comments: string;
  amount: string;
  bill_doc: any;
  expenses_date: string;
  mode_of_payment: string;
}
interface Props {
  siteId: any;
  setRowExpenseSelection: any;
}

const ExpensesListTable: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId, setRowExpenseSelection } = props;
  const [expensesList, setExpensesList] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(
      `site_master_expenses_by_site_id/${siteId}`
    );
    if (response.status === 200) {
      setExpensesList(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const paymentModes = {
    '1': 'Online',
    '2': 'Cash',
    '3': 'Cheque',
    '4': 'DD'
  };
  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'expensemaster.expense_title',
      header: 'Expense Title',
      cell: ({ row: { original } }) => {
        const {
          expensemaster: { expense_title }
        } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {expense_title ?? 'amar'}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'expenses_date',
      header: 'Date',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'paid_amount',
      header: 'Paid Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'mode_of_payment',
      header: 'Payment Mode',
      cell: ({ row: { original } }) => {
        const { mode_of_payment } = original;
        const modeOfPayment =
          paymentModes[mode_of_payment as keyof typeof paymentModes];
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {modeOfPayment}
            </p>
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'comments',
      header: 'Note',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'bill_doc',
      header: 'Document',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: expensesList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    if (table.getGroupedSelectedRowModel().rows.length > 0) {
      const selectedIDs: number[] = [];
      table.getGroupedSelectedRowModel().rows.map(row => {
        selectedIDs.push(row.original.id);
      });
      setRowExpenseSelection(selectedIDs);
    }
  }, [table.getGroupedSelectedRowModel()]);

  return (
    <div>
      <div className="mb-9">
        <h4 className="mb-5">Site Expense</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ExpensesListTable;
