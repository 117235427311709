import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { imageURL, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';
import JsonToCsvButton from 'components/JsonToCsvButton';
import ContractorDropdownItems from 'components/common/ContractorDropdownItems';

interface Material {
  id: number;
  total_stock: string;
  received_stock: string;
  last_name: string;
  email: string;
  mobile_no: number;
  site_count: number;
  address: string;
  profile_image: any;
  material: {
    name: string;
    unitdata: {
      unit_title: string;
    };
  };
}

const ContractorStockListView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contractorlList, setContractorlList] = useState([]);
  const [contractorData, setContractorData] = useState<any>();
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  console.log('contractorData', contractorData);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const filteredData = contractorlList.map((data: any) => ({
    Contractor_name: `${contractorData?.first_name} ${contractorData?.last_name}`,
    Material_name: data.material.name,
    Unit: data.material.unitdata.unit_title,
    Stock: data.total_stock
  }));

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Material>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'received_stock',
      header: 'Received Stock',
      cell: ({ row: { original } }) => {
        const { received_stock } = original;
        return <p className="fw-semibold"> {received_stock}</p>;
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'total_stock',
      header: 'Current Stock',
      cell: ({ row: { original } }) => {
        const { total_stock } = original;
        return <p className="fw-semibold"> {total_stock}</p>;
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'total_stock',
      header: 'Consume Stock',
      cell: ({ row: { original } }) => {
        const { received_stock } = original;
        const { total_stock } = original;
        return (
          <p className="fw-semibold">
            {' '}
            {parseInt(received_stock) - parseInt(total_stock)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: contractorlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
    fetchContractor();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(`contractor_material_stock/${id}`);
    if (response.status === 200) {
      setLoading(false);
      setContractorlList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const fetchContractor = async () => {
    setLoading(true);
    const response = await getService(`contractor/${id}`);
    if (response.status === 200) {
      setLoading(false);
      setContractorData(response.data[0]);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h4 className="mb-5">
          {`Contractor ${contractorData?.first_name} ${contractorData?.last_name}`}
        </h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Contractors Stock Report.csv"
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ContractorStockListView;
