/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type ChangeAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction;
  changeAction?: ChangeAction;
}

const InvDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  changeAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item
        eventKey="2"
        // className="text-danger"
        onClick={editAction}
      >
        Edit Receive Qty
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        // className="text-danger"
        onClick={changeAction}
      >
        Change GST State
      </Dropdown.Item>
    </>
  );
};

export default InvDropdownItems;
