import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import IndustryListTable from 'components/tables/IndustryListTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { addService, deleteService, getService } from 'services/services';
import { validationErrorFromAPI } from 'helpers/utils';
import { ColumnDef } from '@tanstack/react-table';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Link } from 'react-router-dom';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import { Col } from 'react-bootstrap';
import Loader from 'helpers/Loader';

interface Project {
  id: number;
  item_code: string;
  amount: string;
  company: {
    name: string;
  };
}
type EditAction = () => void;

const ItemCodeView = () => {
  const [itemCodeList, setItemCodeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [itemCodeId, setItemCodeId] = useState('');
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  const [fields, setFields] = useState({
    company_id: '',
    amount: '',
    item_code: '',
    item_detail: '',
    unit_id: ''
  });

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setShow(true);
    setItemCodeId(data.id);
    setFields({
      company_id: data.company_id,
      amount: data.amount,
      item_code: data.item_code,
      item_detail: data.item_detail,
      unit_id: data.unitdata.id
    });
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setItemCodeId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`itemcode/${itemCodeId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setItemCodeId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const IndustryListTableColumns: ColumnDef<Project>[] = [
    {
      header: 'item code',
      accessorKey: 'item_code',
      cell: ({ row: { original } }) => {
        const { item_code } = original;
        return (
          <p className=" d-flex align-items-center text-body">
            <p className="mb-0">{item_code}</p>
            <Link to="#" className="mb-0 px-2">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleShow(original)}
              />
            </Link>
          </p>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'item_detail',
      header: 'Service Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' } }
      }
    },
    {
      accessorKey: 'unitdata.unit_title',
      header: 'Unit',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' } }
      }
    },
    {
      accessorKey: 'company.name',
      header: 'Company name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' } }
      }
    },
    {
      accessorKey: 'company.industry.name',
      header: 'Industry',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' } }
      }
    },
    {
      header: 'Rate',
      accessorKey: 'amount',
      // cell: ({ row: { original } }) => {
      //   const { created_at } = original;
      //   return <>{formatDate(created_at)}</>;
      // },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  // removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: itemCodeList,
    columns: IndustryListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleClose = () => {
    setShow(false);
    setItemCodeId('');
    setFields({
      company_id: '',
      amount: '',
      item_code: '',
      item_detail: '',
      unit_id: ''
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const endpoint = itemCodeId ? `itemcode/${itemCodeId}` : 'itemcode';
      const response = await addService(endpoint, fields);

      if (response.status === 200) {
        setLoading(false);
        setShow(false);
        fetch();
        setItemCodeId('');
        setFields({
          company_id: '',
          amount: '',
          item_code: '',
          item_detail: '',
          unit_id: ''
        });
      } else {
        setLoading(false);
        validationErrorFromAPI(response.data);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error saving item:', error);
    }
  };

  const fetch = async () => {
    setLoading(true);
    const response = await getService('itemcode');
    if (response.status === 200) {
      setLoading(false);
      setItemCodeList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  const fetchCompany = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const fetchUnit = async () => {
    const response = await getService('unit');
    if (response.status === 200) {
      setUnitList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
    fetchCompany();
    fetchUnit();
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Item Code'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Item Code-Services</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({itemCodeList.length})
            </span>
          </h2>
          {role === 1 && (
            <Button
              onClick={() => setShow(true)}
              variant="primary"
              className="px-5"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create Item Code
            </Button>
          )}
        </div>
        <ProjectsTopSection activeView="list" />
        <IndustryListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{'Create '}Item Code-Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} xl={12}>
                <Row as="form" className="g-3 mb-2">
                  <Col sm={12} md={12}>
                    <FloatingLabel
                      controlId="floatingSelectPrivacy"
                      label="Select Company"
                    >
                      <Form.Select
                        name="company_id"
                        value={fields['company_id']}
                        onChange={handleSelect}
                      >
                        <option>Select Company</option>
                        {companyList.length > 0 &&
                          companyList.map((industry: any) => (
                            <option key={industry.id} value={industry.id}>
                              {`${industry.name}- ${industry.industry.name}`}
                            </option>
                          ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col sm={4} md={4}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Service Code"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Service Code"
                        name="item_code"
                        value={fields['item_code']}
                        onChange={handleChange}
                        style={{ textTransform: 'uppercase' }}
                        maxLength={15}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col sm={8} md={8}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Service Details"
                    >
                      <Form.Control
                        type="text"
                        placeholder="service_name"
                        name="item_detail"
                        value={fields['item_detail']}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={4} md={4}>
                    <FloatingLabel
                      controlId="floatingSelectPrivacy"
                      label="Select Unit"
                    >
                      <Form.Select
                        name="unit_id"
                        value={fields['unit_id']}
                        onChange={handleSelect}
                      >
                        <option>Select Unit</option>
                        {unitList.length > 0 &&
                          unitList.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {`${item.unit_title}`}
                            </option>
                          ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>

                  <Col sm={8} md={8}>
                    <FloatingLabel controlId="floatingInputGrid" label="Amount">
                      <Form.Control
                        type="text"
                        placeholder="Amount"
                        name="amount"
                        value={fields['amount']}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default ItemCodeView;
