/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type DamageAction = () => void;

interface ActionDropdownItemsProps {
  damageAction?: DamageAction;
}

const StockDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  damageAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" className="text-info" onClick={damageAction}>
        Convert into damage
      </Dropdown.Item>
    </>
  );
};

export default StockDropdownItems;
