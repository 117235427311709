import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import InvoiceListDropdownItems from 'components/common/InvoiceListDropdownItems';
import Loader from 'helpers/Loader';
import InvestorInvDropdownItems from 'components/common/InvestorInvDropdownItems';
import DatePicker from 'components/base/DatePicker';
import JsonToCsvButton from 'components/JsonToCsvButton';

// interface Supplier {
//   id: number;
//   supplier_name: string;
//   business_name: string;
//   gst_no: string;
//   mobile_no: string;
//   address: string;
// }

interface Supplier {
  id: number;
  status: string;
  business_name: string;
  supplier_name: string;
  mobile_no: string;
  gst_no: string;
  pancard_no: string;
  investor: {
    investor_name: string;
    business_name: string;
    mobile_no: string;
    address: string;
  };
  statedata: {
    name: string;
  };
  citydata: {
    name: string;
  };
  address: string;
  bank_name: string;
  // Add more fields as needed

  total_invoice_count: number;
  total_amt: number;
  total_paid: number;
  total_remain: number;
}

interface Field {
  investor_id: string;
  receiver_name: string;
  invoice_id: string;
  amount: string;
  mode_of_payment: string;
  transaction_no: string;
  payment_date: any;
}

const PurchasingListView = () => {
  const navigate = useNavigate();
  const [supplierlList, setSupplierlList] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [investorInvoice, setInvestorInvoice] = useState<any[]>([]);
  const [investorName, setInvestorName] = useState<any>('');
  const [invoiceNo, setInvoiceNo] = useState<any>('');
  const [fields, setFields] = useState<Field>({
    investor_id: '',
    invoice_id: '',
    receiver_name: '',
    amount: '',
    mode_of_payment: '1',
    transaction_no: '',
    payment_date: ''
  });

  console.log('supplierlList', supplierlList);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setSupplierId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`investor_invoice/${supplierId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShowAlert(false);
      setSupplierId('');
      fetch();
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('investor_invoice');
    if (response.status === 200) {
      setLoading(false);
      setSupplierlList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const filteredData = supplierlList.map((data: any) => ({
    Status: data.status,
    investor_name: data.investor.investor_name,
    business_name: data.investor.business_name,
    mobile_no: data.investor.mobile_no,
    gst_no: data.investor.gst_no,
    pancard_no: data.investor.pancard_no,
    address: data.investor.address,
    bank_name: data.investor.bank_name,
    account_no: data.investor.account_no,
    ifsc_code: data.investor.ifsc_code,
    invoice_no: data.invoice_no,
    invoice_date: data.invoice_date,
    gst_amount: data.gst_amount,
    total_amount: data.total_amount,
    total_paid: data.total_paid,
    Remaining_Amount: data.total_remain
  }));

  const handleShow = (data: any) => {
    setInvoiceNo(data.invoice_no);
    setInvestorName(data.investor.investor_name);
    setShow(true);
    setFields((prevState: any) => ({
      ...prevState,
      investor_id: data.investor_id,
      invoice_id: data.id
    }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    // if (id) {
    //   setLoading(true);
    //   response = await addService(`investor_invoice_payment/${id}`, data);
    // } else {
    setLoading(true);
    response = await addService(`add_investor_invoice_payment`, data);
    // }
    if (response?.status === 200) {
      fetch();
      setShow(false);
      setLoading(false);
      toast.success('Payment Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setFields({
        investor_id: '',
        invoice_id: '',
        receiver_name: '',
        amount: '',
        mode_of_payment: '',
        transaction_no: '',
        payment_date: ''
      });
    } else if (response?.status === 500) {
      setShow(false);
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    } else {
      setLoading(false);
      setShow(false);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Supplier>[] = [
    {
      accessorKey: 'supplier_name',
      header: '#',
      cell: ({ row: { original } }) => {
        const { status } = original;

        // Determine the colors for the dots
        const dotColors = {
          '0': 'gray',
          '1': 'green',
          '2': 'yellow',
          '3': 'red'
        };

        // Default colors for two dots
        let colors = [];

        // Check for multiple statuses
        if (status === '1') {
          colors = [dotColors['1']];
        } else if (status === '2') {
          colors = [dotColors['2']];
        } else if (status === '3') {
          colors = [dotColors['3']];
        } else if (status === '1,2') {
          colors = [dotColors['1'], dotColors['2']];
        } else if (status === '1,2,3') {
          colors = [dotColors['1'], dotColors['2'], dotColors['3']];
        } else if (status === '1,3') {
          colors = [dotColors['1'], dotColors['3']];
        } else if (status === '2,3') {
          colors = [dotColors['2'], dotColors['3']];
        } else {
          colors = [dotColors['0']]; // Fallback color
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {colors.map((color: any, index: any) => (
              <div
                key={index}
                style={{
                  backgroundColor: color,
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  marginRight: '4px' // Space between the dots
                }}
              ></div>
            ))}
            <p className="mb-0 ms-2 text-body-emphasis fw-semibold">
              {original.supplier_name}{' '}
              {/* Assuming you want to display the supplier name */}
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '100px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'investor_name',
      header: 'Investor Name',
      cell: ({ row: { original } }) => {
        const { investor_name } = original.investor;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 text-body-emphasis fw-semibold">
              {investor_name}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'investor.business_name',
      header: 'Business Name',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '100px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_no',
      header: 'Invoice No',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Invoice Date',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_amount',
      header: 'GST amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'total_amount',
      header: 'Total Amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'total_remain',
      header: 'Remaining Amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'investor.gst_no',
      header: 'GST No.',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'investor.pancard_no',
      header: 'PAN CARD',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Mobile Number',
      cell: ({ row: { original } }) => {
        const { mobile_no } = original.investor;
        return (
          <Link to={`tel:${mobile_no}`} className="fw-bold text-body-emphasis">
            {mobile_no}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '100px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'investor.address',
      header: 'Address',
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <InvestorInvDropdownItems
                editAction={() => navigate(`/addPurchasing/${id}`)}
                removeAction={() => confirmAlert(id)}
                addPayment={() => handleShow(original)}
                paymentHistory={() => navigate(`/paymentListView/${id}`)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: supplierlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Investor Invoice'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Investor Invoice</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="Investor Invoice Report.csv"
                />
                <Link className="btn btn-primary px-5" to="/addPurchasing">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add
                </Link>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Add Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Investor"
                      >
                        <Form.Control value={investorName} readOnly />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Invoice"
                      >
                        <Form.Control value={invoiceNo} readOnly />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={fields['amount']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <DatePicker
                        // value={selectedEndDate}
                        value={fields['payment_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            payment_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Payment Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectCity"
                        label="Payment mode"
                      >
                        <Form.Select
                          name="mode_of_payment"
                          value={fields['mode_of_payment']}
                          onChange={handleSelect}
                        >
                          <option>Select Status</option>
                          <option value="1">Cash</option>
                          <option value="2">Online</option>
                          <option value="3">Cheque</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Receiver Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Receiver Name"
                          name="receiver_name"
                          value={fields['receiver_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Transaction Id"
                      >
                        <Form.Control
                          placeholder="Transaction Id"
                          name="transaction_no"
                          value={fields['transaction_no']}
                          onChange={handleChange}
                          maxLength={20}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PurchasingListView;
