import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';

interface Invoices {
  id: number;
  stock: number;
  gst_amt: number;
  total_amt: number;
  batch_no: number;
  barcode: number;
  purchase_rate: number;
  material: {
    name: string;
  };
}

const SupplierInvoiceDetails = () => {
  //   const navigate = useNavigate();
  const { id } = useParams();
  const [invoiceList, setInvoiceList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [supplierName, setSupplierName] = useState('');

  console.log('invoiceList', invoiceList);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Invoices>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      meta: {
        headerProps: {
          style: { width: '5%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: {
          style: { width: '5%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Barcode',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'batch_no',
      header: 'Batch No.',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'stock',
      header: 'Quantity',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'purchase_rate',
      header: 'purchase rate',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_amt',
      header: 'GST Amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'total_amt',
      header: 'Total Amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    }
    // {
    //   id: 'action',
    //   cell: ({ row: { original } }) => {
    //     const { id } = original;
    //     return (
    //       <RevealDropdownTrigger>
    //         <RevealDropdown>
    //           {/* <ActionDropdownItems
    //             editAction={() => navigate(`/contractors/create-new/${id}`)}
    //             removeAction={() => confirmAlert(id)}
    //           /> */}
    //         </RevealDropdown>
    //       </RevealDropdownTrigger>
    //     );
    //   },
    //   meta: {
    //     headerProps: { style: { width: '10%' }, className: 'text-end' },
    //     cellProps: { className: 'text-end' }
    //   }
    // }
  ];

  const table = useAdvanceTable({
    data: invoiceList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`invoice_material_detail/${id}`);
    if (response.status === 200) {
      setInvoiceList(response.data);
      setSupplierName(response.data[0]?.supplier?.supplier_name);
      setInvoiceNo(response.data[0]?.invoice_no);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h5 className="mb-4">Invoice Details </h5>
        <Row>
          <Col>
            <p className="mb-4">Invoice No. {invoiceNo}</p>
          </Col>
          <Col>
            <p className="mb-4">Supplier Name: {supplierName}</p>
          </Col>
        </Row>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link
                  className="btn btn-primary px-5"
                  to="/contractors/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add contractor
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SupplierInvoiceDetails;
