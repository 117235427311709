import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import JsonToCsvButton from 'components/JsonToCsvButton';
import InActiveSiteCardViewItem from 'components/modules/project-management/card-view/InActiveSiteCardViewItem';
import InActiveSiteListViewItem from 'components/modules/project-management/card-view/InActiveSiteListViewItem';
import SiteCardViewItem from 'components/modules/project-management/card-view/SiteCardViewItem';
import SiteListViewItem from 'components/modules/project-management/card-view/SiteListViewItem';
// import SiteListViewItem from 'components/modules/project-management/card-view/SiteListViewItem';
import { Site } from 'data/project-management/projects';
import Loader from 'helpers/Loader';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

export const columns: ColumnDef<Site>[] = [
  {
    // For filtering and searching projects by status
    id: 'site_id',
    accessorFn: ({ site_id }) => site_id
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

const InActiveSiteListCardView = () => {
  // const [siteList, setSiteList] = useState([]);
  // const [role, setRole] = useState(0);
  // const [viewUi, setViewUi] = useState(false);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     const role = Number(localStorage.getItem('role'));
  //     setRole(role);
  //   }
  // }, []);

  // const fetch = async () => {
  //   setLoading(true);
  //   const response = await getService('site');
  //   if (response.status === 200) {
  //     setLoading(false);
  //     setSiteList(response.data);
  //     // return <Redirect to="/" />;
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetch();
  // }, []);

  const [siteList, setSiteList] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [role, setRole] = useState(0);
  const [viewUi, setViewUi] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  // For filtering the original list
  const [filteredSiteList, setFilteredSiteList] = useState<Site[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await getService('site_inactive');
      if (response.status === 200) {
        setLoading(false);
        setSiteList(response.data);
        setFilteredSiteList(response.data); // Initialize filtered list
      } else {
        setLoading(false);
      }
    };

    fetch();
  }, []);

  // Update filteredSiteList based on searchTerm
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSiteList(siteList); // Reset to original list
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filteredItems = siteList.filter((item: any) =>
        item.site_name.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredSiteList(filteredItems); // Update filtered list
    }
  }, [searchTerm, siteList]);

  const filteredData = siteList.map((data: any) => ({
    site_name: data.site_name,
    site_id: data.site_id,
    budget: data.budget,
    address: data.address,
    start_date: data.start_date,
    end_date: data.end_date,
    sac_code: data.sac_code,
    uom: data.uom,
    workorder_id: data.workorder.wo_id,
    contractor_name: `${data?.contractor?.first_name} ${data?.contractor?.last_name}`
  }));

  const table = useAdvanceTable<Site>({
    data: filteredSiteList,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">InActive Sites</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({siteList.length})
            </span>
          </h2>
          <Button variant="info" onClick={() => setViewUi(!viewUi)}>
            {viewUi ? 'Card View' : 'List View'}
          </Button>
          {!viewUi && (
            <Form>
              <Form.Group controlId="searchForm">
                <Form.Control
                  type="text"
                  name="searchTerm"
                  placeholder="Search Sites..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          <JsonToCsvButton
            data={filteredData}
            filename="In Active sites Report.csv"
          />
        </div>

        {/* <ProjectsTopSection activeView="card" /> */}
        {/* <div className="d-flex mb-3">
          <Link to="#" className="me-2 tex">
            All({siteList.length})
          </Link>
          <>
            <Link to="#" className="tex">
              3 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'orange' }}>
              (0)
            </p>
          </>{' '}
          <>
            <Link to="#" className="tex">
              5 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'yellow' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Same
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Overdue
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
        </div> */}
        {!viewUi && (
          <Row className="g-3 mb-9">
            {table
              .getRowModel()
              .rows.map(row => row.original)
              .map(site => (
                <Col xs={12} sm={6} xl={4} xxl={3} key={site.id}>
                  <InActiveSiteCardViewItem project={site} />
                </Col>
              ))}
          </Row>
        )}
        {viewUi && <InActiveSiteListViewItem />}
      </AdvanceTableProvider>
    </div>
  );
};

export default InActiveSiteListCardView;
