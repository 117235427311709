import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { formatDate, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';

interface Contractor {
  id: number;
  created_at: any;
  amount: number;
  mode_of_payment: number;
}

const SupplierPaymentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentList, setPaymentList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [supplierName, setSupplierName] = useState('');

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'created_at',
      header: 'Date',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <span className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {formatDate(created_at)}
            </p>
          </span>
        );
      },
      meta: {
        headerProps: {
          style: { width: '55%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Paid Amount',
      meta: {
        headerProps: {
          style: { width: '5%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mode_of_payment',
      header: 'Mode of Payment',
      cell: ({ row: { original } }) => {
        const { mode_of_payment } = original;
        return (
          <span className="fw-bold text-body-emphasis">
            {mode_of_payment === 1
              ? 'Cash'
              : mode_of_payment === 2
              ? 'Cheque'
              : mode_of_payment === 3
              ? 'Online'
              : ''}
          </span>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'address',
      header: 'Actions',
      // cell: ({ row: { original } }) => {
      //   // const { mode_of_payment } = original;
      //   return (
      //     <a
      //       className="fw-bold text-body-emphasis"
      //       style={{ cursor: 'pointer' }}
      //       onClick={() => navigate('/paymentReceipt')}
      //     >
      //       Print
      //     </a>
      //   );
      // },
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <Link
            to={`/paymentReceipt/${id}`}
            className="text-decoration-none fw-bold fs-8"
          >
            Print
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    }
    // {
    //   id: 'action',
    //   cell: ({ row: { original } }) => {
    //     const { id } = original;
    //     return (
    //       <RevealDropdownTrigger>
    //         <RevealDropdown>
    //           {/* <ActionDropdownItems
    //             editAction={() => navigate(`/contractors/create-new/${id}`)}
    //             removeAction={() => confirmAlert(id)}
    //           /> */}
    //         </RevealDropdown>
    //       </RevealDropdownTrigger>
    //     );
    //   },
    //   meta: {
    //     headerProps: { style: { width: '10%' }, className: 'text-end' },
    //     cellProps: { className: 'text-end' }
    //   }
    // }
  ];

  const table = useAdvanceTable({
    data: paymentList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(
      `show_invoice_payment_by_invoice_id/${id}`
    );
    if (response.status === 200) {
      setPaymentList(response.data);
      setInvoiceNo(response.data[0]?.invoice?.invoice_no);
      setInvoiceAmount(response.data[0]?.invoice?.total_amount);
      setSupplierName(response.data[0]?.supplier?.supplier_name);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h5 className="mb-1">Payment Details</h5>
        <Row>
          <Col>
            <p className="mb-0">Invoice No. {invoiceNo}</p>
          </Col>
          <Col>
            <p className="mb-4">Invoice Amount {invoiceAmount}</p>
          </Col>
          <Col>
            <p className="mb-4">Supplier Name: {supplierName}</p>
          </Col>
        </Row>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link
                  className="btn btn-primary px-5"
                  to="/contractors/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add contractor
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SupplierPaymentDetails;
