import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  faFileExport,
  faFileLines,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ApprovalDropdownItems from 'components/common/ApprovalDropdownItems';
import ApprovalModal from 'components/modals/ApprovalModal';
import { validationErrorFromAPI } from 'helpers/utils';

interface PendingListTable {
  id: number;
  full_name: string;
  site: {
    site_id: number;
    site_name: string;
  };
  material: {
    name: string;
  };
  status: string;
}

const SitePendingListTable = () => {
  const [pendingList, setPendingList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<PendingListTable>[] = [
    {
      accessorKey: 'site.site_name',
      header: 'Site Name',
      meta: {
        headerProps: {
          // style: { width: '15%', minWidth: '200px' },
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'site.site_id',
      header: 'Site ID',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'full_name',
      header: 'Contractor',
      cell: ({ row: { original } }) => {
        const { full_name } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {full_name}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'name',
      header: 'Material List View',
      cell: ({ row: { original } }) => {
        const { name } = original.material;
        return (
          <>
            {name} <FontAwesomeIcon icon={faFileLines} />
          </>
        );
      },

      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <>
            {status == '1'
              ? 'Pending'
              : status == '2'
              ? 'Approve to Main'
              : status == '3'
              ? 'Approve to Local'
              : status == '4'
              ? 'Reject'
              : ''}
          </>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ApprovalDropdownItems
                // editAction={() => navigate(`/suppliers/create-new/${id}`)}
                approvalAction={() => confirmAlert(id)}
                rejectAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: pendingList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService('estimatedSiteInventories_pending_list');
    if (response.status === 200) {
      const contractorsWithFullName = response.data.map(
        (item: {
          site: { contractor: { first_name: any; last_name: any } };
        }) => ({
          ...item,
          full_name: `${item.site.contractor?.first_name ?? ''} ${
            item.site.contractor?.last_name ?? ''
          }`
        })
      );
      setPendingList(contractorsWithFullName);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {showAlert && (
        <ApprovalModal
          name={'Material'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Site Material Pending List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link
                  className="btn btn-primary px-5"
                  to="/contractors/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add contractor
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SitePendingListTable;
