import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate, useParams } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDate,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import { FloatingLabel } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import InventoryDropdownItems from 'components/common/InventoryDropdownItems';
import JsonToCsvButton from 'components/JsonToCsvButton';

interface Invoices {
  invoice_no: number;
  id: number;
  total_amount: number;
  total_paid: number;
  total_remain: number;
  invoice_date: any;
  supplier: {
    supplier_name: string;
    id: number;
  };
}

const SupplierInvoiceTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [invoiceList, setInvoiceList] = useState([]);
  const [editData, setEditData] = useState<any>([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [show, setShow] = useState(false);

  const [fields, setFields] = useState({
    supplier_id: '',
    invoice_id: '',
    amount: '',
    mode_of_payment: '',
    transaction_no: '',
    receiver_name: ''
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (data: any) => {
    setShow(true);
    setEditData(data);
    setFields({
      ...fields,
      supplier_id: data.supplier_id,
      invoice_id: data.id
    });
  };

  const handleSave = async () => {
    const data = jsonToFormData(fields);
    let response: any = {};

    response = await addService(`supplier_invoice_payment`, data);

    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
      await navigate(`/paymentReceipt/${response?.data?.id}`);
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
    // }
  };

  const filteredData = invoiceList.map((data: any) => ({
    Supplier_Name: data.supplier.supplier_name,
    Invoice_No: data.invoice_no,
    Invoice_Date: formatDate(data.invoice_date),
    GST_Amount: data.gst_amount,
    Total_Amount: data.total_amount,
    Total_Paid: data.total_paid,
    Remaining_Amount: data.total_remain
  }));

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Invoices>[] = [
    {
      accessorKey: 'supplier.supplier_name', // Change accessorKey to 'invoice'
      header: 'Supplier Name',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '100px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'invoice_no', // Change accessorKey to 'invoice'
      header: 'Invoice No.',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '150px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'total_amount', // Change accessorKey to 'invoice'
      header: 'Invoice Amount',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '150px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Invoice Date',
      cell: ({ row: { original } }) => {
        const { invoice_date } = original;
        return <p>{formatDate(invoice_date)}</p>;
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_amount',
      header: 'GST Amount',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'total_paid',
      header: 'Paid Amount',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '100px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'total_remain',
      header: 'Balance Amount',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <InventoryDropdownItems
                // editAction={() => navigate(`/contractors/create-new/${id}`)}
                // removeAction={() => confirmAlert(id)}
                addPaymentAction={() => handleShow(original)}
                paymentDetailsAction={() =>
                  navigate(`/supplierPaymentDetails/${id}`)
                }
                invoiceDetailsAction={() =>
                  navigate(`/supplierInvoiceDetails/${id}`)
                }
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: invoiceList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`supplier_invoice_detail/${id}`);
    if (response.status === 200) {
      setInvoiceList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h4 className="mb-5">Supplier Invoice Table</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="Supplier Invoice List.csv"
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Add Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 py-1">
                    <Row className="mt-2">
                      <Col>
                        <h6>Invoice No: {editData?.invoice_no}</h6>
                      </Col>
                      <Col>
                        <h6>
                          Invoice Date: {formatDate(editData?.invoice_date)}
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <h6>Invoice Amount: {editData?.total_amount}</h6>
                      </Col>
                      <Col>
                        <h6>Paid Amount: {editData?.total_paid}</h6>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <h6>Remaining Amount: {editData?.total_remain}</h6>
                      </Col>
                    </Row>
                    <div className="px-3">
                      <h5>Supplier: {editData?.supplier?.supplier_name}</h5>
                    </div>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Amount"
                          name="amount"
                          // value={fields.amount}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingSelectTask"
                        label="Mode of Payment"
                      >
                        <Form.Select
                          name="mode_of_payment"
                          value={fields.mode_of_payment}
                          onChange={handleChange}
                        >
                          <option>Select Payment Mode</option>
                          <option value="1">Cash</option>
                          <option value="2">Cheque</option>
                          <option value="3">Online</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>

                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Transaction Id"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Transaction Id"
                          name="transaction_no"
                          // value={fields.transaction_no}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Receiver Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Receiver Name"
                          name="receiver_name"
                          value={fields.receiver_name}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Change
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SupplierInvoiceTable;
