import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
// import logo from 'assets/img/icons/logo.png';
import arklogo from 'assets/img/icons/arklogo.png';
import srsclogo from 'assets/img/icons/srsc_logo.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';

const NavbarBrand = () => {
  const {
    config: { navbarTopShape, navbarPosition }
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to="/"
          className={classNames({
            'me-1 me-sm-3':
              navbarTopShape === 'slim' || navbarPosition === 'horizontal'
          })}
        >
          {navbarTopShape === 'slim' ? (
            <>
              Avark{' '}
              <span className="text-body-highlight d-none d-sm-inline">
                slim
              </span>
            </>
          ) : (
            <div className="d-flex align-items-center flex-wrap">
              {/* <img src={arklogo} alt="Avark" width={50} /> */}
              <img
                src={srsclogo}
                alt="SHREE RAM SAGAR CONSTRUCTION"
                width={200}
              />
              {/* <img
                src="https://shriramsagar.com/wp-content/uploads/2024/08/1356CONSTRUCTION.png"
                alt="SHREE RAM SAGAR CONSTRUCTION"
                width={200}
                // style={{ height: 'auto', maxWidth: '100%' }}
                className="img-fluid"
              /> */}
              {/* <p className="logo-text ms-2 d-none d-sm-block">Avark</p> */}
              {/* <p className="logo-text d-none d-sm-block ">
                SHREE RAM SAGAR CONSTRUCTION
              </p> */}
            </div>
          )}
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
