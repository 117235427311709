/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type StockDetails = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  stockDetails?: StockDetails;
}

const ContractorDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  stockDetails = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item eventKey="2" className="info" onClick={stockDetails}>
        Stock details
      </Dropdown.Item>
    </>
  );
};

export default ContractorDropdownItems;
