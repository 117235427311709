import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
// import RevealDropdown, {
//   RevealDropdownTrigger
// } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import InvoiceListDropdownItems from 'components/common/InvoiceListDropdownItems';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import Loader from 'helpers/Loader';
import JsonToCsvButton from 'components/JsonToCsvButton';
// import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
// import QuotationDropdown from 'components/common/QuotationDropdown';
// import Loader from 'helpers/Loader';

// interface Supplier {
//   id: number;
//   supplier_name: string;
//   business_name: string;
//   gst_no: string;
//   mobile_no: string;
//   address: string;
// }

interface Supplier {
  id: number;
  company: {
    name: string;
  };
  challandetail: [];
  total_material_amt: number;
  contractor: {
    first_name: string;
    last_name: string;
  };
}

const QuotationListView = () => {
  const navigate = useNavigate();
  const [quotationList, setQuotationList] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [quotationId, setQuotationId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [quotaion, setQuotaion] = useState<any[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setSupplierId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`supplier/${supplierId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShowAlert(false);
      setSupplierId('');
      fetch();
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
    getCompany();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('contractorchallan');
    if (response.status === 200) {
      setLoading(false);
      setQuotationList(response.data);
      setQuotaion(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  const getCompany = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const filteredData = quotationList.map((data: any) => ({
    Challan_No: data.id,
    Challan_Date: data.q_date,
    Material_Count: data.challandetail.length,
    Total_Amount: data.total_material_amt,
    Contractor_Name: `${data.contractor.first_name} ${data.contractor.last_name}`,
    License_No: data.contractor.license_no,
    Address: data.contractor.address,
    Mobile_No: data.mobile_number,
    Email: data.email,
    Aadhar_No: data.contractor.aadhar_no
  }));

  const handleQuotationChange = async (quotationId: string) => {
    if (quotationId) {
      setQuotationId(quotationId);
      const response = await getService(
        `contractorchallan/${quotationId}/${companyId ? companyId : 0}`
      );
      if (response.status === 200) {
        setQuotationList(response.data);
      } else {
        setQuotationList(response.data);
      }
    } else {
      `contractorchallan/${quotationId ? quotationId : 0}/${
        companyId ? companyId : 0
      }`;
    }
  };

  const handleCompanyChange = async (comId: string) => {
    if (comId) {
      setCompanyId(comId);
      const response = await getService(
        `contractorchallan/${quotationId ? quotationId : 0}/${comId}`
      );
      if (response.status === 200) {
        setQuotationList(response.data);
      } else {
        setQuotationList(response.data);
      }
    } else {
      `contractorchallan/${quotationId ? quotationId : 0}/${
        companyId ? companyId : 0
      }`;
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Supplier>[] = [
    {
      accessorKey: 'id',
      header: 'Challan No',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <Link
            to={`/newChallan/${id}`}
            className=" d-flex align-items-center text-body"
          >
            <p className="mb-0 text-body-emphasis fw-semibold">{id}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '150px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'Contractor Name',
      header: 'Contractor Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original.contractor;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">{`${first_name} ${last_name}`}</p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '150px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mobile_number',
      header: 'mobile_number',
      meta: {
        headerProps: { style: { width: '15%', minWidth: '150px' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      meta: {
        headerProps: { style: { width: '15%', minWidth: '150px' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Material Count',
      cell: ({ row: { original } }) => {
        const { challandetail } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {challandetail.length}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '150px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'total_material_amt',
      header: 'Value',
      cell: ({ row: { original } }) => {
        const { total_material_amt } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">
            {Math.round(total_material_amt)}
          </p>
        );
      },
      meta: {
        headerProps: { style: { width: '15%', minWidth: '150px' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'address',
      header: 'Actions',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <Link
            to={`/newChallan/${id}`}
            className="text-decoration-none fw-bold fs-8"
          >
            Print
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => navigate(`/quotation/create-new/${id}`)}
                // removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: quotationList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Challan'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Contractor Material Details</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Challan">
                  <Form.Select
                    name="Challan Filter"
                    onChange={e => handleQuotationChange(e.target.value)}
                  >
                    <option value="0">Select ID</option>
                    {quotaion.length > 0 &&
                      quotaion.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.id}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Contractor"
                >
                  <Form.Select
                    name="Client Filter"
                    onChange={e => handleCompanyChange(e.target.value)}
                  >
                    <option value="0">Select Contractor</option>
                    {companyList.length > 0 &&
                      companyList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {`${item.first_name} ${item.last_name}`}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="Contractor Challan List.csv"
                />
                <Link
                  className="btn btn-primary px-5"
                  to="/quotation/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Create Challan
                </Link>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default QuotationListView;
