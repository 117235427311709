import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import JsonToCsvButton from 'components/JsonToCsvButton';
import SiteCardViewItem from 'components/modules/project-management/card-view/SiteCardViewItem';
import SiteListViewItem from 'components/modules/project-management/card-view/SiteListViewItem';
// import SiteListViewItem from 'components/modules/project-management/card-view/SiteListViewItem';
import { Site } from 'data/project-management/projects';
import Loader from 'helpers/Loader';
import { validationErrorFromAPI } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

export const columns: ColumnDef<Site>[] = [
  {
    // For filtering and searching projects by status
    id: 'site_id',
    accessorFn: ({ site_id }) => site_id
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

interface WorkOrder {
  id: number;
  wo_id: string;
}
interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
  business_name: string;
}

const SiteListCardView = () => {
  // const [siteList, setSiteList] = useState([]);
  // const [role, setRole] = useState(0);
  // const [viewUi, setViewUi] = useState(false);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     const role = Number(localStorage.getItem('role'));
  //     setRole(role);
  //   }
  // }, []);

  // const fetch = async () => {
  //   setLoading(true);
  //   const response = await getService('site');
  //   if (response.status === 200) {
  //     setLoading(false);
  //     setSiteList(response.data);
  //     // return <Redirect to="/" />;
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetch();
  // }, []);

  const [siteList, setSiteList] = useState<Site[]>([]);
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [contId, setContId] = useState('');
  const [woId, setWoId] = useState('');
  const [role, setRole] = useState(0);
  const [viewUi, setViewUi] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  // For filtering the original list
  const [filteredSiteList, setFilteredSiteList] = useState<Site[]>([]);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('site');
    if (response.status === 200) {
      setLoading(false);
      setSiteList(response.data);
      setFilteredSiteList(response.data); // Initialize filtered list
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    fetchWorkOrder();
    fetchContractor();
  }, []);

  const fetchWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const fetchContractor = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setContractorList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleWorkOrderChange = async (id: any) => {
    if (id) {
      setWoId(id);
      const response = await getService(`site/${id}/${contId ? contId : 0}`);
      if (response.status === 200) {
        setSiteList(response.data);
      } else {
        setSiteList([]);
      }
    } else {
      `site/${woId ? woId : 0}/${contId ? contId : 0}`;
    }
  };
  const handleContractorChange = async (id: any) => {
    if (id) {
      setContId(id);
      const response = await getService(`site/${woId ? woId : 0}/${id}`);
      if (response.status === 200) {
        setSiteList(response.data);
      } else {
        setSiteList([]);
      }
    } else {
      `site/${woId ? woId : 0}/${contId ? contId : 0}`;
    }
  };

  // Update filteredSiteList based on searchTerm
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSiteList(siteList); // Reset to original list
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filteredItems = siteList.filter((item: any) =>
        item.site_name.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredSiteList(filteredItems); // Update filtered list
    }
  }, [searchTerm, siteList]);

  const filteredData = siteList.map((data: any) => ({
    site_name: data.site_name,
    site_id: data.site_id,
    budget: data.budget,
    address: data.address,
    start_date: data.start_date,
    end_date: data.end_date,
    sac_code: data.sac_code,
    uom: data.uom,
    workorder_id: data.workorder.wo_id,
    contractor_name: `${data.contractor.first_name} ${data.contractor.last_name}`
  }));

  const table = useAdvanceTable<Site>({
    data: filteredSiteList,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Sites</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({siteList.length})
            </span>
          </h2>
          <Button variant="info" onClick={() => setViewUi(!viewUi)}>
            {viewUi ? 'Card View' : 'List View'}
          </Button>
          {!viewUi && (
            <Form>
              <Form.Group controlId="searchForm">
                <Form.Control
                  type="text"
                  name="searchTerm"
                  placeholder="Search Sites..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          <Col xs="auto">
            <FloatingLabel controlId="floatingSelectTask" label="Work Order">
              <Form.Select
                name="Work Order"
                onChange={e => handleWorkOrderChange(e.target.value)}
              >
                <option value="0">Select Work Order</option>
                {workOrderList.length > 0 &&
                  workOrderList.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col xs="auto">
            <FloatingLabel controlId="floatingSelectTask" label="Contractor">
              <Form.Select
                name="Contractor"
                onChange={e => handleContractorChange(e.target.value)}
              >
                <option value="0">Select Contractor</option>
                {contractorList.length > 0 &&
                  contractorList.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {`${item.business_name}-${item.first_name} ${item.last_name}`}
                    </option>
                  ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          {role === 1 && (
            <Link className="btn btn-primary px-5" to="/site/create-new">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create New Site
            </Link>
          )}
          <JsonToCsvButton
            data={filteredData}
            filename="Active Site Report.csv"
          />
        </div>

        {/* <ProjectsTopSection activeView="card" /> */}
        {/* <div className="d-flex mb-3">
          <Link to="#" className="me-2 tex">
            All({siteList.length})
          </Link>
          <>
            <Link to="#" className="tex">
              3 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'orange' }}>
              (0)
            </p>
          </>{' '}
          <>
            <Link to="#" className="tex">
              5 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'yellow' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Same
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Overdue
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
        </div> */}
        {!viewUi && (
          <Row className="g-3 mb-9">
            {table
              .getRowModel()
              .rows.map(row => row.original)
              .map(site => (
                <Col xs={12} sm={6} xl={4} xxl={3} key={site.id}>
                  <SiteCardViewItem project={site} />
                </Col>
              ))}
          </Row>
        )}
        {viewUi && <SiteListViewItem />}
      </AdvanceTableProvider>
    </div>
  );
};

export default SiteListCardView;
