import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import {
  faEdit,
  faFileExport,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDate,
  handleKeyDown,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import { FloatingLabel } from 'react-bootstrap';
import Loader from 'helpers/Loader';
import JsonToCsvButton from 'components/JsonToCsvButton';

interface Industry {
  id: number;
  name: string;
  created_at: any;
}
interface Common {
  id: number;
  name: string;
}
interface Project {
  id: number;
  name: string;
  created_at: string;
  pan_no: string;
  gst_no: string;
  address: string;
}
interface Field {
  industry_id: string;
  name: string;
  state: string;
  country: string;
  city: string;
  pincode: string;
  address: string;
  pan_no: string;
  gst_no: string;
}

type EditAction = () => void;

const CompanyListView = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [companyList, setCompanyList] = useState<Project[]>([]);
  const [fields, setFields] = useState<Field>({
    industry_id: '',
    name: '',
    country: '101',
    state: '',
    city: '',
    pincode: '',
    address: '',
    pan_no: '',
    gst_no: ''
  });
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setCompanyId(data.id);
    setShow(true);
    setFields({
      industry_id: data.industry_id,
      name: data.name,
      country: data.country,
      state: data.state,
      city: data.city,
      pincode: data.pincode,
      address: data.address,
      pan_no: data.pan_no,
      gst_no: data.gst_no
    });
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setCompanyId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`company/${companyId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setCompanyId('');
    }
  };

  const handleClose = () => {
    setShow(false);
    setCompanyId('');
    setCompanyName('');
    setIndustryName('');
    setError('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  const getState = async () => {
    const response = await getService(`state-by-country/${101}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  const handleSave = async () => {
    const data = jsonToFormData(fields);

    let response: any = {};
    if (companyId) {
      setLoading(true);
      response = await addService(`company/${companyId}`, data);
    } else {
      setLoading(true);
      response = await addService(`company`, data);
    }
    if (response?.status === 200) {
      setLoading(false);
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
    } else if (response?.status === 500) {
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
    // }
  };

  const filteredData = companyList.map((data: any) => ({
    Company_Name: data.name,
    GST_No: data.gst_no,
    PAN_No: data.pan_no,
    Address: data.address,
    Pin_Code: data.pincode,
    Industry_Name: data.industry.name
  }));

  const fetch = async () => {
    setLoading(true);
    const response = await getService('company');
    if (response.status === 200) {
      setLoading(false);
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
    fetchIndustry();
  }, []);

  const fetchIndustry = async () => {
    const response = await getService('industrie');
    if (response.status === 200) {
      setIndustryList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Industry>[] = [
    {
      accessorKey: 'name',
      header: 'Company Name',
      cell: ({ row: { original } }) => {
        const { name } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 text-body-emphasis fw-semibold">{name}</p>
            <p className="mb-0 px-2">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleShow(original)}
              />
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'id',
      header: 'ID',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'industry.name',
      header: 'Industry Name',
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'pan_no',
      header: 'PAN NO.',
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_no',
      header: 'GST NO.',
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'address',
      header: 'Address',
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'created_at',
      header: 'CREATE DATE',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return <>{formatDate(created_at)}</>;
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },

    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: companyList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Company'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Company</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="Company List.csv"
                />
                {role === 1 && (
                  <Button
                    onClick={() => setShow(true)}
                    variant="primary"
                    className="px-5"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Company
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {companyId ? 'Edit ' : 'Create '}Company
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="Select Industry"
                      >
                        <Form.Select
                          name="industry_id"
                          value={fields['industry_id']}
                          onChange={handleSelect}
                        >
                          <option>Select Industry</option>
                          {industryList.length > 0 &&
                            industryList.map(industry => (
                              <option key={industry.id} value={industry.id}>
                                {industry.name}
                              </option>
                            ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Company Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="name"
                          value={fields['name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Country"
                      >
                        <Form.Control
                          type="text"
                          placeholder="India"
                          name="India"
                          value={'India'}
                          // onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="State"
                      >
                        <Form.Select
                          name="state"
                          value={fields['state']}
                          onChange={handleSelect}
                        >
                          <option>Select State</option>
                          {state.length > 0 &&
                            state.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectTeam"
                        label="City"
                      >
                        <Form.Select
                          name="city"
                          value={fields['city']}
                          onChange={handleSelect}
                        >
                          <option>Select City</option>
                          {city.length > 0 &&
                            city.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Address"
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="address"
                          value={fields['address']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="PIN code"
                      >
                        <Form.Control
                          placeholder="Mobile Numbar"
                          name="pincode"
                          value={fields['pincode']}
                          onChange={handleChange}
                          maxLength={6}
                          onKeyDown={handleKeyDown}
                          pattern="[0-9]*"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="PAN no."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="pan_no"
                          value={fields['pan_no']}
                          onChange={handleChange}
                          maxLength={10}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </FloatingLabel>
                    </Col>

                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="GST no."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Mobile Numbar"
                          name="gst_no"
                          value={fields['gst_no']}
                          onChange={handleChange}
                          maxLength={15}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CompanyListView;
