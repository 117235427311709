import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  faEdit,
  faFileExport,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { imageURL, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';
import JsonToCsvButton from 'components/JsonToCsvButton';
import ContractorDropdownItems from 'components/common/ContractorDropdownItems';

interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: number;
  site_count: number;
  address: string;
  profile_image: any;
}

const ContractorsListView = () => {
  const navigate = useNavigate();
  const [contractorlList, setContractorlList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const filteredData = contractorlList.map((data: any) => ({
    contractor_name: `${data.first_name} ${data.last_name}`,
    mobile_no: data.mobile_no,
    email: data.email,
    aadhar_no: data.aadhar_no,
    license_no: data.license_no,
    address: data.address,
    site_count: data.site_count
  }));

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'full_name',
      header: 'Contractor Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name, profile_image, id } = original;
        return (
          <Link to="#" className=" d-flex align-items-center text-body">
            <Avatar src={`${imageURL}/public/${profile_image}`} size="m" />
            <p
              onClick={() => navigate(`/contractorProfile/${id}`)}
              className="mb-0 ms-3 text-body-emphasis fw-semibold"
            >
              {first_name} {last_name}
            </p>
            <p className="mb-0 px-2">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => navigate(`/contractors/create-new/${id}`)}
              />
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          // style: { width: '15%', minWidth: '200px' },
          style: { width: '15%', minWidth: '100px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'business_name',
      header: 'Business Name',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_no',
      header: 'GST',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original;
        return (
          <Link to={`mailto:${email}`} className="fw-semibold">
            {email}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Mobile Number',
      cell: ({ row: { original } }) => {
        const { mobile_no } = original;
        return (
          <Link to={`tel:${mobile_no}`} className="fw-bold text-body-emphasis">
            {mobile_no}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'address',
      header: 'Address',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'site_count',
      header: 'No. of Sites',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ContractorDropdownItems
                  editAction={() => navigate(`/contractors/create-new/${id}`)}
                  // removeAction={() => confirmAlert(id)}
                  stockDetails={() => navigate(`/contractorStockList/${id}`)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: contractorlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('contractor');
    if (response.status === 200) {
      setLoading(false);
      // setContractorlList(response.data);
      const contractorsWithFullName = response.data.map(
        (contractor: Contractor) => ({
          ...contractor,
          full_name: `${contractor.first_name} ${contractor.last_name}`
        })
      );
      setContractorlList(contractorsWithFullName);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Contractor</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Contractors List.csv"
                />
                {role === 1 && (
                  <Link
                    className="btn btn-primary px-5"
                    to="/contractors/create-new"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add contractor
                  </Link>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ContractorsListView;
