import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row
} from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  dateFormat,
  formatDateForAPI,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import JsonToCsvButton from 'components/JsonToCsvButton';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import InvDropdownItems from 'components/common/InvDropdownItems';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';
import StockDropdownItems from 'components/common/StockDropdownItems';

interface Inventory {
  total_amt: any;
  gst_amt: any;
  stock: any;
  receive_qty: any;
  invoice_no: string;
  material: {
    name: string;
    current_stock: number;
  };
  id: number;
  batch_no: number;
  barcode: number;
  purchase_rate: number;
  maintan_date: string;
}
interface Fields {
  damage_date: string | Date;
  remark: string;
  damage_qty: string;
  inventory_id: string;
}

const MasterInventoryStock = () => {
  const navigate = useNavigate();
  const [inventoryList, setInventoryList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [inventoryId, setInventoryId] = useState('');
  const [materialData, setMaterialData] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [changeShow, setChangeShow] = useState(false);
  const [editData, setEditData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [sbuId, setSbuId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');

  const [fields, setFields] = useState<Fields>({
    inventory_id: '',
    damage_qty: '',
    damage_date: '',
    remark: ''
  });

  const [fieldsEdit, setFieldsEdit] = useState<any>({
    supplier_id: '',
    material_id: '',
    invoice_no: '',
    receive_qty: '',
    batch_no: '',
    maintan_date: '',
    stock: '',
    purchase_rate: '',
    gst: '',
    gst_amt: '',
    total_amt: ''
  });
  const handleShow = (data: any) => {
    setMaterialData(data);
    setShow(true);
    setInventoryId(data.id);
  };
  // const confirmAlert = (id: any) => {
  //   setShowAlert(true);
  //   setItemCodeId(id);
  // };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSbuId(event.target.value);
  };

  const handleChangeEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFieldsEdit((prevFields: any) => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleEditShow = (data: any) => {
    setSelectedId(data.id);
    setEditData(data);
    setEditShow(true);
    setFieldsEdit({
      supplier_id: data.supplier_id,
      material_id: data.material_id,
      invoice_no: data.invoice_no,
      receive_qty: data.receive_qty,
      batch_no: data.batch_no,
      maintan_date: data.maintan_date,
      stock: data.stock,
      purchase_rate: data.purchase_rate,
      gst: data.gst,
      gst_amt: data.gst_amt,
      total_amt: data.total_amt
    });
  };

  const handleChangeShow = (data: any) => {
    setChangeShow(true);
    setEditData(data);
    setSbuId(data.masterinvoice.sbu_id);
    setInvoiceId(data.master_invoice_id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };
  const handleSave = async () => {
    fields.inventory_id = inventoryId;
    if (parseInt(fields.damage_qty) > materialData.total_stock) {
      toast.error(
        `Cannot convert more damage than the current stock. Available stock is ${materialData.total_stock}`
      );
    } else {
      try {
        setLoading(true);
        const response = await addService('master_inventory_damage', fields);

        if (response.status === 200) {
          setLoading(false);
          toast.success('Added SuccessFully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
          setShow(false);
          fetch();
          setInventoryId('');
          setFields({
            inventory_id: '',
            damage_qty: '',
            remark: '',
            damage_date: ''
          });
        } else if (response?.status === 500) {
          setLoading(false);
          validationErrorFromAPI(response.data);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error saving item:', error);
      }
    }
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      const response = await addService(
        `master_inventory/${selectedId}`,
        fieldsEdit
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success('Added SuccessFully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        setEditShow(false);
        fetch();
        setInventoryId('');
        setFields({
          inventory_id: '',
          damage_qty: '',
          remark: '',
          damage_date: ''
        });
      } else if (response?.status === 500) {
        setLoading(false);
        validationErrorFromAPI(response.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };
  const handlChangeGst = async () => {
    try {
      setLoading(true);
      const response = await addService(
        `update_master_inventory_sbu_by_incoice_id/${invoiceId}`,
        { sbu_id: sbuId }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success('Added SuccessFully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        setChangeShow(false);
        fetch();
        setInventoryId('');
        setFields({
          inventory_id: '',
          damage_qty: '',
          remark: '',
          damage_date: ''
        });
      } else if (response?.status === 500) {
        setLoading(false);
        validationErrorFromAPI(response.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setEditShow(false);
    setChangeShow(false);
    setInventoryId('');
    setFields({
      inventory_id: '',
      damage_qty: '',
      remark: '',
      damage_date: ''
    });
    setFieldsEdit({
      state_gst: '',
      receive_qty: ''
    });
  };

  //   const fetchMaterial = async () => {
  //     const response = await getService('material');
  //     if (response.status === 200) {
  //       setMaterialList(response.data);
  //     }
  //   };

  const fetchState = async () => {
    const response = await getService('gstlist');
    if (response.status === 200) {
      setStateList(response.data);
    }
  };

  const handleStateChange = async (selectedStateId: string) => {
    if (selectedStateId) {
      setStateId(selectedStateId);
      const response = await getService(
        `master_inventory_stock/${selectedStateId ? selectedStateId : 0}`
      );
      if (response.status === 200) {
        setInventoryList(response.data);
      } else {
        setInventoryList(response.data);
      }
    }
  };

  const filteredData = inventoryList.map((data: any) => ({
    material: data.material.name,
    unit_title: data.material.unitdata.unit_title,
    stock: data.total_stock,
    SBU_Name: data.sbudetail.sbu_name,
    GST_No: data.sbudetail.gst_no,
    State: data.sbudetail.state.name
  }));

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Inventory>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'total_stock',
      header: 'Stock',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'sbudetail.sbu_name',
      header: 'SBU',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'sbudetail.gst_no',
      header: 'GST No',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'sbudetail.state.name',
      header: 'State Name',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <StockDropdownItems damageAction={() => handleShow(original)} />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
    // fetchMaterial();
    fetchState();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(`master_inventory_stock/${0}`);
    if (response.status === 200) {
      setLoading(false);
      setInventoryList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    } else {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Master Inventory Stock</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search"
                  onChange={handleSearchInputChange}
                />
              </Col>
              {/* <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Material">
                  <Form.Select
                    name="Material Filter"
                    onChange={e => handleMaterialChange(e.target.value)}
                  >
                    <option value="0">Select Material</option>
                    {materialList.length > 0 &&
                      materialList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col> */}
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="State">
                  <Form.Select
                    name="Material Filter"
                    onChange={e => handleStateChange(e.target.value)}
                  >
                    <option value="0">Select State</option>
                    {stateList.length > 0 &&
                      stateList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {`${item.sbu_name} - ${item.state.name}`}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Master Inventory Stock.csv"
                />
                {/* <Link className="btn btn-primary px-5" to="/CreateInventory">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Inventory
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                Is {materialData?.material?.name} damaged?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-2">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Damage Qty"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Item Code"
                          name="damage_qty"
                          value={fields['damage_qty']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fields['damage_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            damage_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Select damage date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <FloatingLabel
                        controlId="floatingProjectOverview"
                        label="Write Damage Remark"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Write Damage Remark"
                          name="remark"
                          value={fields['remark']}
                          onChange={handleChange}
                          style={{ height: '100px' }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editShow} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-2">
                    <div className="p-1">
                      <p>Material Name: {editData?.material?.name}</p>
                      <Row>
                        <Col>
                          <p>Stock: {editData?.stock}</p>
                        </Col>
                        <Col>
                          <p>Amount: {editData?.gst_amt}</p>
                        </Col>
                      </Row>
                    </div>
                    <Col sm={12} md={12}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Receive qty"
                      >
                        <Form.Control
                          type="number    "
                          placeholder="Last Name"
                          name="receive_qty"
                          value={
                            fieldsEdit['receive_qty'] !== undefined
                              ? fieldsEdit['receive_qty']
                              : editData.receive_qty || ''
                          }
                          onChange={handleChangeEdit}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleEdit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={changeShow} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Change GST State</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-2">
                    <div className="p-1">
                      <p>Material Name: {editData?.material?.name}</p>
                      <Row>
                        <Col>
                          <p>Stock: {editData?.stock}</p>
                        </Col>
                        <Col>
                          <p>Amount: {editData?.gst_amt}</p>
                        </Col>
                      </Row>
                    </div>
                    <Col sm={12} md={12}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="State GST"
                      >
                        <Form.Select
                          name="sbuId"
                          value={sbuId}
                          onChange={handleSelect}
                        >
                          <option value="0">Select State</option>
                          {stateList.length > 0 &&
                            stateList.map((item: any) => (
                              <option key={item.id} value={item.id}>
                                {item.state.name}
                              </option>
                            ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handlChangeGst}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MasterInventoryStock;
