import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SiteStageListTable from './SiteStageListTable';
import AddSiteInventory from './AddSiteInventory';
import InventoryViewTable from './InventoryViewTable';
import ServisesViewTable from './ServisesViewTable';

interface Props {
  siteId: any; // Define the prop type
}
const InventoryListTable: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId } = props;
  const [activeKey, setActiveKey] = useState<string>('Add Site Inventory');
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const activeTabStyle = {
    backgroundColor: '#3874FF',
    color: 'white',
    fontSize: '20px'
  };
  const inactiveTabStyle = {
    // backgroundColor: 'white',
    // color: 'black',
    fontSize: '20px'
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="Add Site Inventory"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex mt-2"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        {role === 1 && (
          <Tab
            eventKey="Add Site Inventory"
            title={
              <span
                className="border rounded px-6"
                style={
                  activeKey === 'Add Site Inventory'
                    ? activeTabStyle
                    : inactiveTabStyle
                }
              >
                Add Site Inventory
              </span>
            }
          >
            {activeKey === 'Add Site Inventory' && (
              <AddSiteInventory siteId={siteId} />
            )}
          </Tab>
        )}
        <Tab
          eventKey="View Inventory"
          title={
            <span
              className="border rounded px-6"
              style={
                activeKey === 'View Inventory'
                  ? activeTabStyle
                  : inactiveTabStyle
              }
            >
              View Inventory
            </span>
          }
        >
          {activeKey === 'View Inventory' && (
            <InventoryViewTable siteId={siteId} />
          )}
        </Tab>
        <Tab
          eventKey="View Servises"
          title={
            <span
              className="border rounded px-6"
              style={
                activeKey === 'View Servises'
                  ? activeTabStyle
                  : inactiveTabStyle
              }
            >
              View Servises
            </span>
          }
        >
          {activeKey === 'View Servises' && (
            <ServisesViewTable siteId={siteId} />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default InventoryListTable;
